<template>
  <div class="search-result">
    <div class="d-flex">
      <slot name="index" />
      <div>{{ filename }}</div>
      <div class="ml-auto">
        {{ humanize(size) }}
      </div>
    </div>
    <a
      v-if="showUrl"
      :href="url"
      target="_blank"
      class="link text-caption"
    >
      {{ url }}
    </a>
    <pre
      v-if="attachment && attachment.content"
      class="mt-2"
      v-html="attachment.content.join('')"
    />
  </div>
</template>

<script>
import { isUrl } from '@/util/util'
import humanizeSizeMixin from '@/mixins/humanize-size'

export default {
  name: 'SearchCodeResult',
  mixins: [humanizeSizeMixin],
  props: {
    attachment: {
      type: Object,
      default: () => ({}),
    },
    filename: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    showUrl () {
      return isUrl(this.url)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result::v-deep em {
  background: yellow;
}

.link {
  white-space: nowrap;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  border: thin solid var(--v-outline-base);
  border-radius: $border-radius-small;
  padding: $spacer * 2 $spacer * 4;
  font-size: 0.625rem;
}
</style>
