<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" lg>
        <v-checkbox
          v-model="personnel.staff"
          :ripple="false"
          label="Силами штатных сотрудников"
          value="staff"
          @change="toggleStaff"
          class="mt-0 mt-md-4"
        />
        <v-text-field
          ref="inputStaff"
          :disabled="disabled || !personnel.staff"
          :error-messages="feedback('name')"
          :loading="disabled"
          label="Количество сотрудников"
          :value="personnel.staffInfo"
          outlined
        />
      </v-col>
      <v-col cols="12" md="4" lg>
        <v-checkbox
          v-model="personnel.citizens"
          :ripple="false"
          label="Силами физических лиц — граждан РФ"
          value="citizens"
          @change="toggleCitizens"
          class="mt-0 mt-md-4"
        />
        <v-text-field
          ref="inputCitizens"
          :disabled="disabled || !personnel.citizens"
          :error-messages="feedback('name')"
          :loading="disabled"
          label="Количество граждан РФ"
          :value="personnel.citizensInfo"
          outlined
        />
      </v-col>
      <v-col cols="12" md="4" lg="auto">
        <v-checkbox
          v-model="personnel.foreigners"
          :ripple="false"
          label="Силами физических лиц — иностранных граждан"
          value="foreigners"
          @change="toggleForeigners"
          class="mt-0 mt-md-4"
        />
        <v-text-field
          ref="inputForeigners"
          :disabled="disabled || !personnel.foreigners"
          :error-messages="feedback('name')"
          :loading="disabled"
          label="Количество иностранных граждан"
          :value="personnel.foreignersInfo"
          outlined
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>
        <v-checkbox
          v-model="personnel.outsource"
          :ripple="false"
          label="Силами сторонней организации"
          value="outsource"
          @change="toggleOutsource"
          class="mt-0"
        />
        <v-text-field
          ref="inputOutsource"
          :disabled="disabled || !personnel.outsource"
          :error-messages="feedback('name')"
          :loading="disabled"
          label="Наименование, ОГРН, сведения о цепочке бенефициаров"
          :value="personnel.outsourceInfo"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FormAppPersonnel',
  props: {
    disabled: {type: Boolean},
    personnel: {
      type: Object,
      default () {
        return {
          staff: false,
          staffInfo: '',
          outsource: false,
          outsourceInfo: '',
          citizens: false,
          citizensInfo: '',
          foreigners: false,
          foreignersInfo: '',
        }
      },
    },
  },
  methods: {
    feedback () {
      return ''
    },
    toggleStaff (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.inputStaff.focus()
        })
      }
    },
    toggleOutsource (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.inputOutsource.focus()
        })
      }
    },
    toggleCitizens (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.inputCitizens.focus()
        })
      }
    },
    toggleForeigners (value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.inputForeigners.focus()
        })
      }
    },
  },
}
</script>

<style scoped>
</style>
