<template>
  <v-card class="pa-5" flat outlined>
    <h3 class="mb-5">
      {{ $t('Правообладатель') }}
      {{ index }}
    </h3>
    <v-select
      v-model="rightsHolder.type"
      :items="rightsHolderTypes"
      :label="$t('Статус правообладателя')"
      item-text="title"
      item-value="value"
      outlined
    />

    <!-- citizen -->
    <template v-if="rightsHolder.type === 'citizen'">
      <v-row>
        <v-col>
          <v-text-field
            v-model="rightsHolder.rh_last_name"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Фамилия')"
            :loading="disabled"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.rh_first_name"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Имя')"
            :loading="disabled"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.rh_middle_name"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Отчество')"
            :loading="disabled"
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="docType"
            :items="citizenDocTypes"
            :label="$t('Документ, удостоверяющий личность гражданина')"
            item-text="title"
            item-value="value"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.doc_number"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Серия и номер')"
            :loading="disabled"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.doc_date"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Дата выдачи')"
            :loading="disabled"
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="rightsHolder.doc_org_code"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Код подразделения')"
            :loading="disabled"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.doc_org"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('Наименование органа, выдавшего документ')"
            :loading="disabled"
            outlined
          />
        </v-col>
      </v-row>

      <v-text-field
        v-model="rightsHolder.rh_inn"
        :disabled="disabled"
        :error-messages="feedback('name')"
        :label="$t('ИНН')"
        :loading="disabled"
        outlined
      />
      <v-text-field
        v-model="rightsHolder.citizen_address_reg"
        :disabled="disabled"
        :error-messages="feedback('name')"
        :label="$t('Адрес регистрации по месту жительства (пребывания)')"
        :loading="disabled"
        outlined
      />
      <v-text-field
        v-model="rightsHolder.citizen_address_real"
        :disabled="disabled"
        :error-messages="feedback('name')"
        :label="$t('Адрес места фактического проживания')"
        :loading="disabled"
        outlined
      />
    </template>

    <!-- not citizen -->
    <template v-if="rightsHolder.type && rightsHolder.type !== 'citizen'">
      <v-text-field
        v-model="rightsHolder.rh_short"
        :disabled="disabled"
        :error-messages="feedback('name')"
        :label="$t('Краткое название')"
        :loading="disabled"
        outlined
      />
      <v-text-field
        v-model="rightsHolder.rh_full"
        :disabled="disabled"
        :error-messages="feedback('name')"
        :label="$t('Полное название')"
        :loading="disabled"
        outlined
      />
      <v-row>
        <v-col>
          <v-text-field
            v-model="rightsHolder.rh_ogrn"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('ОГРН')"
            :loading="disabled"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="rightsHolder.rh_inn"
            :disabled="disabled"
            :error-messages="feedback('name')"
            :label="$t('ИНН')"
            :loading="disabled"
            outlined
          />
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'FormAppRightsHolder',
  props: {
    disabled: {type: Boolean},
    index: {type: Number, required: true},
    rightsHolder: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      rightsHolderTypes: [
        {value: 'rf', title: 'Российская Федерация'},
        {value: 'rf_region', title: 'Субъект Российской Федерации'},
        {value: 'municipal', title: 'Муниципальное образование'},
        {value: 'commercial', title: 'Российская коммерческая организация'},
        {
          value: 'commercial_foreign',
          title: 'Российская коммерческая организация, имеющая в цепочке владения иностранных лиц (иностранные организации или трасты)'
        },
        {value: 'nonprofit', title: 'Российская некоммерческая организация'},
        {value: 'citizen', title: 'Гражданин Российской Федерации'},
      ],
      citizenDocTypes: [
        {value: 'inner', title: 'Паспорт гражданина РФ'},
        {value: 'pass', title: 'Загранпаспорт гражданина РФ'},
        {value: 'mil', title: 'Удостоверение личности военнослужащего РФ'},
        {value: 'temp', title: 'Временное удостоверение личности гражданина РФ'},
      ],
      docType: 'inner',
    }
  },
  methods: {
    feedback () {
      return ''
    },
  },
}
</script>

<style scoped>
</style>
