<template>
  <v-container>
    <h1 class="mb-10 text-h1">
      {{ $t('Search code') }}
    </h1>
    <v-form ref="form" @submit.prevent>
      <v-textarea
        v-model="code"
        :counter="maxCodeLength"
        :error-messages="serverFeedback.search"
        :label="$t('Enter code for search')"
        :loading="loading"
        :rows="10"
        :rules="rules"
        auto-grow
        autofocus
        class="deposit-content--code"
        outlined
        @input="serverFeedback.search = null"
      />
      <v-radio-group v-model="searchType" :disabled="loading" class="mt-0">
        <v-radio value="match_phrase" :label="$t('Exact search')" />
        <v-radio value="match" :label="$t('Fuzzy search')" />
      </v-radio-group>
      <v-btn
        :disabled="loading"
        :loading="loading"
        :ripple="false"
        class="mt-1 btn-light-border-gradient"
        color="primary"
        outlined
        rounded
        @click="onSearch"
      >
        {{ $t('Search') }}
      </v-btn>
    </v-form>

    <template v-if="!loading">
      <h3 v-if="total === 0" class="my-10">
        {{ $t('Nothing found') }}
      </h3>
      <v-row v-if="total > 0" class="my-10">
        <v-col>
          <div class="d-flex mb-3">
            <div class="text-h5 mr-10">
              {{ $t('Search found', {count: total}) }}
            </div>
            <div class="text-h5">
              {{ $t('Uniqueness', {unique: unique}) }}
            </div>
          </div>

          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="`sr-${i}`"
              class="mb-8 px-0"
            >
              <search-code-result v-bind="item">
                <template v-slot:index>
                  <div class="mr-1">{{ i + 1 }}.</div>
                </template>
              </search-code-result>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </template>

    <div v-show="canLoadMore" ref="more" />
    <v-row v-show="loadingMore" justify="center">
      <v-col cols="auto">
        <v-progress-circular class="mb-5" color="primary" indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import error401handler from '@/mixins/error-401-handler'
import SearchCodeResult from '@/components/SearchCodeResult.vue'

export default {
  name: 'SearchCode',
  components: {SearchCodeResult},
  mixins: [error401handler],
  data () {
    return {
      code: '',
      maxCodeLength: 10 * 1000,
      loading: false,
      loadingMore: false,
      serverFeedback: {},
      searchType: 'match_phrase',
      items: [],
      total: null,
      unique: null,
      perPage: 5,
      from: 0,
      dirty: false,
      rules: [
        v => !this.dirty || !!v || this.$t('Content should not be empty'),
        v => (v || '').length <= this.maxCodeLength || this.$t('Input text is too long'),
      ],
      observer: null,
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.loadMore()
        }
      })
    })
  },
  computed: {
    canLoadMore () {
      return this.total > 0 && this.from + this.perPage < this.total
    },
  },
  methods: {
    ...mapActions({
      search: 'searchCode/search',
    }),
    async onSearch () {
      if (this.$refs.form.validate()) {
        this.resetSearch()
        this.loading = true

        let {items, total, unique} = await this.loadSearchResults()
        this.items = items
        this.total = total
        this.unique = unique

        this.$nextTick(() => {
          if (this.$refs.more) {
            this.observer.observe(this.$refs.more)
          }
        })
      }
    },
    async loadMore () {
      this.from += this.perPage
      this.loadingMore = true
      let {items, total, unique} = await this.loadSearchResults()
      this.items.push(...items)
      this.total = total
      this.unique = unique
    },
    async loadSearchResults () {
      let result = {items: [], total: null, unique: null}
      this.serverFeedback = {}
      try {
        result = await this.search({
          search: this.code,
          type: this.searchType,
          size: this.perPage,
          from: this.from,
        })
      } catch (e) {
        if (e.response?.status === 400) {
          this.serverFeedback.search = this.$t('ErrorSearchFailed')
        } else if (!this.handleError(e) && e.response?.status === 422) {
          this.serverFeedback = e.response.data.message
        }
      } finally {
        this.loading = false
        this.loadingMore = false
      }
      return result
    },
    resetSearch () {
      if (this.$refs.more) {
        this.observer.unobserve(this.$refs.more)
      }
      this.items = []
      this.total = null
      this.unique = null
      this.from = 0
    },
  },
}
</script>
