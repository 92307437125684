<template>
  <v-icon v-if="check" v-text="icons.check" color="green" />
</template>

<script>
import {mdiCheck} from '@mdi/js'

export default {
  name: 'CheckMark',
  props: {
    check: {type: Boolean},
  },
  data () {
    return {
      icons: {
        check: mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  min-width: 24px;
  margin-left: -28px;
}
</style>
