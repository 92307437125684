<template>
  <div>
    <v-overlay :value="loading" opacity="0" absolute>
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="!loading">
      <v-container>
        <h1 class="text-h1 mt-10">
          Fast Track в Реестр отечественного ПО
        </h1>

        <v-form ref="form" class="mt-10" v-model="valid">

          <v-row>
            <v-col cols="1">
              <CheckMark :check="!!app.title" />
              1.1
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Название ПО') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('Текущее название ПО без номеров версий продукта') }}
              </p>
              <v-text-field
                v-model="app.title"
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <CheckMark :check="!!app.titleAlt" />
              1.2
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Предыдущие и (или) альтернативные названия ПО') }}
              </p>
              <p class="caption text--secondary">
                {{
                  $t('Отличные от п. 1.1. названия ПО, которые могут фигурировать во внутренней документации или указывать на различную версионность ПО')
                }}
              </p>
              <v-text-field
                v-model="app.titleAlt"
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              <CheckMark :check="!!app.description" />
              1.3
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Описание ПО') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('Одно-три предложения с описанием назначения ПО') }}
              </p>
              <v-textarea
                v-model="app.description"
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.4
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Функциональные характеристики ПО') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('Один-два абзаца с кратким описанием функциональных характеристик ПО') }}
              </p>
              <v-textarea
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                :value="app.functions"
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.5
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Основной и дополнительные классы ПО') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('см. столбец 3 Приложения №1') }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.6
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Код (коды) продукции') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('см. столбец 4 Приложения № 1') }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.8
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Сведения о правообладателях ПО') }}
              </p>
              <p class="caption text--secondary">
                • в отношении организации: полное и краткое наименование, ОГРН, ИНН.
                <br>
                • в отношении гражданина: фамилия, имя, отчество, ИНН, наименование и реквизиты документа,
                удостоверяющего личность гражданина Российской Федерации, адрес регистрации по месту жительства
                (пребывания), адрес места фактического проживания
              </p>

              <FormAppRightsHolder
                v-for="(rh, i) in app.rightsHolders"
                :key="`rh-${i}`"
                :disabled="disabled"
                :rights-holder="rh"
                :index="i + 1"
                class="mb-5"
              />

              <v-btn
                :disabled="disabled"
                :ripple="false"
                color="secondary"
                outlined
                rounded
                @click="app.rightsHolders.push({})"
              >
                {{ $t('Добавить правообладателя') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.10
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Адрес электронной почты и номер телефона, по которому осуществляется связь с заявителем') }}
              </p>
              <p class="caption text--secondary">
                {{ $t('Действующие контакты, по которым будут направляться уведомления, а также запросы о предоставлении дополнительной информации') }}
              </p>
              <v-row>
                <v-col>
                  <v-text-field
                    :error-messages="feedback('name')"
                    :label="$t('Email')"
                    :value="profile.email"
                    disabled
                    filled
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    :error-messages="feedback('name')"
                    :label="$t('Номер телефона')"
                    :value="app.phoneNumber"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.11
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                Адрес страницы сайта правообладателя в сети «Интернет», на которой размещена документация, содержащая:
                <br>
                • описание функциональных характеристик ПО;
                <br>
                • информацию, необходимую для установки ПО;
                <br>
                • информацию, необходимую для эксплуатации ПО (пользовательскую документацию)
              </p>
              <p class="caption text--secondary">
                1) для «коробочных» решений содержит информацию, необходимую для установки вашего ПО (в виде отдельного
                текстового файла, доступного для скачивания с Вашего сайта либо в виде уведомления с необходимой
                информацией);
                <br>
                2) для SaaS-решений – уведомление: «ПО распространяется в виде интернет-сервиса, специальные действия по
                установке ПО на стороне пользователя не требуются»;
              </p>
              <v-text-field
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                :value="app.urlDocs"
                outlined
                placeholder="https://www.website.ru"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.12
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                Адрес страницы сайта правообладателя в сети «Интернет», на которой размещена:
                <br>
                • информация о стоимости ПО или порядке ее определения или
                <br>
                • сведения о возможности использования ПО на условиях открытой лицензии или иного безвозмездного
                лицензионного договора.
              </p>
              <p class="caption text--secondary">
                В том числе допускается фраза по типу «Стоимость определяется индивидуально. Обратитесь [указать
                контакты]»
              </p>
              <v-text-field
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                :value="app.urlPrice"
                outlined
                placeholder="https://www.website.ru"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              1.13
              <span class="error--text">*</span>
            </v-col>
            <v-col>
              <p class="mb-0">
                Основания возникновения у правообладателя (правообладателей) исключительного права на ПО
                на территории всего мира и на весь срок действия исключительного права
              </p>
              <p class="caption text--secondary">
                На основании чего у правообладателя возникло исключительное право
              </p>
              <v-radio-group v-model="app.rightsSource">
                <v-radio
                  :label="$t('в силу создания (собственной разработки)')"
                  value="developed"
                />
                <v-radio
                  :label="$t('в силу приобретения (по договору отчуждения, при вкладе в уставной капитал и т.п.)')"
                  value="bought"
                />
              </v-radio-group>
            </v-col>
          </v-row>

          <hr class="my-10">

          <v-row>
            <v-col cols="1">
              2.1
            </v-col>
            <v-col>
              <p class="mb-0">
                {{ $t('Копия устава правообладателя ПО') }}
              </p>
              <p class="caption text--secondary">
                Если заявление подается организацией, необходимо предоставить отсканированную версию устава в формате
                pdf одним файлом
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.2
            </v-col>
            <v-col>
              <p class="mb-0">
                Ссылка на экземпляр ПО
              </p>
              <p class="caption text--secondary">
                Может быть представлена в одном из следующих вариантов:<br>
                А) действующая ссылка на скачивание дистрибутива ПО;<br>
                Б) действующая ссылка на скачивание преднастроенного образа виртуальной машины;<br>
                В) параметры доступа к собственной инфраструктуре с развернутым экземпляром ПО.
              </p>
              <v-text-field
                :disabled="disabled"
                :error-messages="feedback('name')"
                :loading="disabled"
                :value="app.urlPackage"
                outlined
                placeholder="https://www.website.ru"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.3
            </v-col>
            <v-col>
              <p class="mb-0">
                Инструкция (в виде одного документа в произвольной форме) по скачиванию и установке или по удаленному
                доступу к инфраструктуре (VPN, SSH, RDP и т.п.), включающую, при необходимости, лицензионный ключ
                (серийный номер) для активации
              </p>
              <p class="caption text--secondary">
                Документ для эксперта Минцифры. Подробно опишите процесс установки предоставленного на экспертизу
                экземпляра ПО и/или параметры доступа к инфраструктуре с предустановленным для экспертизы экземпляром
                ПО).
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.4
            </v-col>
            <v-col>
              <p class="mb-0">
                Документы, подтверждающие принадлежность исключительного права на ПО
              </p>
              <p class="caption text--secondary">
                А) Если Вы создали ПО своими силами – предоставьте комплект внутренних актов организации:<br>
                • приказ на разработку ПО в рамках служебного задания (о начале, окончании разработки);<br>
                • техническое задание на разработку ПО;<br>
                • отчет о выполнении служебного задания;<br>
                • акт приема-передачи ПО;<br>
                • приказ о постановке нематериального актива (НМА) на бухучет / карточку учета НМА.<br>
                Б) Если Вы получили права на ПО в рамках договора с третьим лицом:<br>
                • договор о создании ПО;<br>
                • договор об отчуждении исключительного права на ПО;<br>
                • акт приема-передачи ПО;<br>
                • приказ о постановке нематериального актива (НМА) на бухучет / карточку учета НМА.<br>
                В) Если ПО было внесено в уставный капитал:<br>
                • решение о создании общества (в соответствующей части), которое содержит условие об отчуждении
                исключительного права на ПО ИЛИ<br>
                • договор об отчуждении исключительного права на ПО.<br>
                Г) Если Вы получили исключительное право на ПО в результате правопреемства:<br>
                • передаточный акт;<br>
                • приказ о постановке нематериального актива (НМА) на бухучет / карточку учета НМА.<br>
                В качестве дополнительного документа, подтверждающего принадлежность прав, может выступать свидетельство
                Роспатента о регистрации ПО.
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.5
            </v-col>
            <v-col>
              <p class="mb-0">
                Документы, подтверждающие отсутствие преобладающего иностранного участия (менее 50%)
              </p>
              <p class="caption text--secondary">
                А) Для российских коммерческих юридических лиц:<br>
                • выписка из ЕГРЮЛ (Для ООО)/ выписка из реестра акционеров (для АО/ПАО);<br>
                • ФИО и паспортные данные (если бенефициар – физическое лицо);<br>
                Б) Для российских коммерческих юридических лиц, имеющих в цепочке владения иностранных лиц:<br>
                • документ, раскрывающий цепочку владения и, в том числе, конечных российских бенефициаров (в форме
                таблицы с указание бенефициаров и долей (процентов) их участия):<br>
                • выписки или иные документы из реестров соответствующих государств, подтверждающих данные таблицы.
                Документы, выполненные на иностранном языке, должны сопровождаться нотариально заверенным переводом
                текста на русский язык.<br>
                Представлять информацию обо всех конечных бенефициарах – российских гражданах не обязательно, важно
                подтвердить отсутствие иностранного контроля (российским лицам должно принадлежать более 50% доли/
                акций).
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.8
            </v-col>
            <v-col>
              <p class="mb-0">
                Документация, содержащая полное описание функциональных характеристик ПО
              </p>
              <p class="caption text--secondary">
                Полностью и подробно опишите функциональные характеристики предоставленного на экспертизу экземпляра ПО
                в текстовом формате
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.9
            </v-col>
            <v-col>
              <p class="mb-0">
                Документация, содержащая информацию, необходимую для эксплуатации ПО
              </p>
              <p class="caption text--secondary">
                В текстовом формате
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.10
            </v-col>
            <v-col>
              <p class="mb-0">
                Документация (в текстовом формате), содержащая:<br>
                • описание процессов, обеспечивающих поддержание жизненного цикла ПО, в том числе устранение
                неисправностей, выявленных в ходе эксплуатации ПО и совершенствование ПО;<br>
                • информацию о фактическом наличии персонала, задействованного в процессах обеспечения жизненного цикла;<br>
                • информацию об фактическом адресе размещения инфраструктуры разработки, разработчиков и служб
                поддержки.
              </p>
              <p class="caption text--secondary">
              </p>
              <v-text-field disabled filled outlined placeholder="Файлы документов" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.11
            </v-col>
            <v-col>
              <p class="mb-0">
                Информация о гарантийном обслуживании, технической поддержке и модернизации ПО
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.11.1
            </v-col>
            <v-col>
              <p class="mb-0">
                Гарантийное обслуживание ПО осуществляется:
              </p>
              <FormAppPersonnel
                :disabled="disabled"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.11.2
            </v-col>
            <v-col>
              <p class="mb-0">
                Техническая поддержка ПО осуществляется:
              </p>
              <FormAppPersonnel
                :disabled="disabled"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1">
              2.11.3
            </v-col>
            <v-col>
              <p class="mb-0">
                Модернизация ПО осуществляется:
              </p>
              <FormAppPersonnel
                :disabled="disabled"
              />
            </v-col>
          </v-row>

        </v-form>
      </v-container>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import CheckMark from '@/components/CheckMark'
import FormAppRightsHolder from '@/components/FormAppRightsHolder'
import FormAppPersonnel from '@/components/FormAppPersonnel'

export default {
  name: 'Application',
  components: {FormAppPersonnel, FormAppRightsHolder, CheckMark},
  data () {
    return {
      loading: false,
      disabled: false,
      valid: true,
      app: {
        title: '',
        warrantyAgent: '',
        supportAgent: '',
        upgradeAgent: '',
        rightsHolders: [{}],
      },
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
    }),
  },
  methods: {
    feedback () {
      return ''
    },
  },
}
</script>

<style scoped>
</style>
